import React from 'react'
import { useQuery } from 'react-query'
import Spinner from '../../components/Spinner'
import { fetchDocuments } from '../../api/documents'
import { Link } from 'react-router-dom'

export const DocumentsTile = () => {
    const { data, isLoading } = useQuery(['documents'], fetchDocuments)

    if (isLoading) return <Spinner />

    return (
        <div className="col-12 col-xl-12">
            <div className="py-4 px-5 px-sm-2 px-md-5 px-xxl-5 border row row-cols-1 g-0">
                <div className="mb-4 mb-md-0 col">
                    <h5>Dokumenty</h5>
                    <div className="row row-cols-3 g-0">
                        <div className="col-4 text-left">
                            <span>Liczba dokumentów</span>
                        </div>
                        <div className="col-4 d-flex justify-content-start align-items-center">
                            <span>{data!.length}</span>
                        </div>
                        <div className="col-4 text-left">
                            <Link to={'/documents'}>Przejdź do dokumentów</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DocumentsTile
