import React from 'react'
import HolidaysTile from 'components/Dashboard/HolidaysTile'
import ProfileTile from 'components/Dashboard/ProfileTile'
import DocumentsTile from 'components/Dashboard/DocumentsTile'

export const Dashboard = () => {
    return (
        <div className="row row-cols-1 g-3">
            <HolidaysTile />
            <ProfileTile />
            <DocumentsTile />
        </div>
    )
}

export default Dashboard
