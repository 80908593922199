import { fetchQuestions } from 'api/applications'
import QuestionsForm from 'components/Applications/QuestionsForm'
import Spinner from 'components/Spinner'
import React from 'react'
import { Container } from 'react-bootstrap'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'

export const Questions = () => {
    const params = useParams()
    const id = +params.id!
    const { isLoading, data } = useQuery(['questions', id], () =>
        fetchQuestions(id)
    )

    if (isLoading) return <Spinner />

    return (
        <Container>
            <QuestionsForm questions={data!} id={id} />
        </Container>
    )
}

export default Questions
