import React from 'react'
import { Nav } from 'react-bootstrap';
import { FaPlus, FaTasks } from "react-icons/fa";
import { useQuery } from "react-query";
import { fetchHoliday } from "../../api/users";
import Spinner from "../Spinner";
import { Link } from "react-router-dom";

export const HolidaysTile = () => {
    const {data, isLoading} = useQuery('holiday', fetchHoliday)

    if (isLoading) return <Spinner/>

    return (
        <div className="col">
            <div className="py-4 px-5 px-sm-2 px-md-5 px-xxl-5 border row row-cols-1 row-cols-sm-2 g-0">
                <div className="mb-4 mb-sm-0 col">
                    <h5>Urlopy</h5>
                    <div className="row row-cols-3 g-0 fw-bold mb-4">
                        <div className="col-10 text-left">
                            <span>Ilość dni do wykorzystania:</span>
                        </div>
                        <div className="col-1 d-flex justify-content-center align-items-center">
                            <span>&nbsp;</span>
                        </div>
                        <div className="col-1 d-flex justify-content-center align-items-center">
                            <span>{data?.numberOfHolidayDays}</span>
                        </div>
                    </div>
                    <div className="row row-cols-3 g-0">
                        <div className="col-10 text-left">
                            <span>Tworzenie wniosków:</span>
                        </div>
                        <div className="col-1 d-flex justify-content-center align-items-center">
                            <span>&nbsp;</span>
                        </div>
                        <div className="col-1 d-flex justify-content-center align-items-center">
                            <Nav.Link
                                as={Link}
                                to="/applications/new"
                                className="m-0 p-0"
                                style={{color: '#000'}}
                            >
                                <FaPlus role="button"></FaPlus>
                            </Nav.Link>
                        </div>
                    </div>
                    <div className="row row-cols-3 g-0">
                        <div className="col-10 text-left">
                            <span>Lista wniosków:</span>
                        </div>
                        <div className="col-1 d-flex justify-content-center align-items-center">
                            <span>&nbsp;</span>
                        </div>
                        <div className="col-1 d-flex justify-content-center align-items-center">
                            <Nav.Link
                                as={Link}
                                to="/applications/list"
                                className="m-0 p-0"
                                style={{color: '#000'}}
                            >
                                <FaTasks role="button"></FaTasks>
                            </Nav.Link>
                        </div>
                    </div>
                </div>
                <div className="col d-flex justify-content-center align-items-center">
                    <img src={`/placeholder.png`} alt="" width="270"/>
                </div>
            </div>
        </div>
    )
}

export default HolidaysTile