import React, { useEffect, useState } from 'react'
import { createContext } from 'react'
import axios from 'axios'
import { Errors, Login, LoginPayload, User } from '../models'

interface Props {
    errors: null | Errors
    accessToken: null | string
    profile: null | User
    logout: () => void
    clearErrors: () => void
    login: (data: Login) => void
}

export const AuthContext = createContext({} as Props)

export const AuthProvider: React.FC<{ children: React.ReactElement }> = ({
    children,
}: any) => {
    const [errors, setErrors] = useState<Errors | null>(null)
    const [accessToken, setAccessToken] = useState<string | null>(null)
    const [profile, setProfile] = useState<User | null>(null)

    useEffect(() => {
        setToken(localStorage.getItem('team.token') || '')
        setProfile(JSON.parse(localStorage.getItem('team') || '{}'))
    }, [])

    const setToken = (token: string) => {
        setAccessToken(token)
        localStorage.setItem('team.token', token)
    }

    // const setUser = (user: User) => {
    //     localStorage.setItem('team.user', JSON.stringify(user))
    //     setProfile(user)
    // }

    return (
        <AuthContext.Provider
            value={{
                accessToken,
                profile,
                logout: () => {
                    setAccessToken(null)
                    setProfile(null)
                    localStorage.removeItem('team.token')
                    localStorage.removeItem('team.user')
                },
                errors,
                clearErrors: () => setErrors(null),
                login: async (data: Login) => {
                    const payload: LoginPayload = {
                        username: data.username,
                        password: data.password,
                        client_id: data.username,
                        client_secret: '',
                        grant_type: 'password',
                    }
                    try {
                        const resp = await axios.post(
                            `${process.env.REACT_APP_API_URL}/oauth`,
                            payload
                        )

                        const token = resp.data.access_token
                        // const decoded = jwt_decode<{ user: User }>(token)

                        setToken(token)
                        // setUser(decoded.user)
                    } catch (err: any) {
                        if ([400, 401].includes(err.response.status)) {
                            setErrors({
                                login: ['Niepoprawny login lub hasło'],
                            })
                        } else {
                            setErrors(err.response.data)
                        }
                    }
                },
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}
