import React from 'react'
import { useQuery } from 'react-query'
import { fetchDocuments } from 'api/documents'
import Spinner from 'components/Spinner'
import { Table } from 'react-bootstrap'
import dayjs from 'dayjs'
import { DocumentTypeName } from 'models/documents'
import Download from '../components/Download'

export const Documents = () => {
    const { isLoading, data } = useQuery(['documents'], fetchDocuments)

    if (isLoading) return <Spinner />

    return (
        <Table>
            <thead>
                <tr>
                    <th>Typ</th>
                    <th>Nazwa</th>
                    <th>Data dodania</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {data!.map((document) => (
                    <tr key={document.id}>
                        <td>{DocumentTypeName[document.type]}</td>
                        <td>{document.title}</td>
                        <td>{dayjs(document.creationDate).format('YYYY-MM-DD HH:mm')}</td>
                        <td>
                            <Download url={`/api/documents/${document.id}/download`} filename={document.title} />
                        </td>
                    </tr>
                ))}
            </tbody>
        </Table>
    )
}

export default Documents
