import { fetchApplications } from 'api/applications'
import Spinner from 'components/Spinner'
import dayjs from 'dayjs'
import React from 'react'
import { Table } from 'react-bootstrap'
import { useQuery } from 'react-query'
import Download from 'components/Download'
import Accept from 'components/Applications/Accept'
import Decline from 'components/Applications/Decline'

export const Mine = () => {
    const { data, isLoading } = useQuery('fulfillments', fetchApplications)

    if (isLoading) return <Spinner />

    return (
        <Table className="align-middle" responsive="sm">
            <thead>
                <tr>
                    <th
                        className="
                        d-none
                        d-sm-none
                        d-md-none
                        d-lg-table-cell
                        d-xl-table-cell
                        d-xxl-table-cell
                    "
                    >
                        Wniosek
                    </th>
                    <th
                        className="
                        d-none
                        d-sm-none
                        d-md-none
                        d-lg-table-cell
                        d-xl-table-cell
                        d-xxl-table-cell
                    "
                    >
                        Data
                    </th>
                    <th
                        className="
                        d-none
                        d-sm-none
                        d-md-none
                        d-lg-table-cell
                        d-xl-table-cell
                        d-xxl-table-cell
                    "
                    >
                        Składający
                    </th>
                    <th
                        className="
                        d-table-cell
                        d-sm-table-cell
                        d-md-table-cell
                        d-lg-none
                        d-xl-none
                        d-xxl-none
                    "
                    >
                        &nbsp;
                    </th>
                    <th
                        className="
                        d-none
                        d-sm-table-cell
                        d-md-table-cell
                        d-lg-table-cell
                        d-xl-table-cell
                        d-xxl-table-cell
                    "
                    >
                        Status
                    </th>
                    <th>&nbsp;</th>
                    <th>&nbsp;</th>
                </tr>
            </thead>
            <tbody>
                {data?.map((application) => (
                    <tr>
                        <td
                            className="
                            d-none
                            d-sm-none
                            d-md-none
                            d-lg-table-cell
                            d-xl-table-cell
                            d-xxl-table-cell
                        "
                        >
                            {application.name}
                        </td>
                        <td
                            className="
                            d-none
                            d-sm-none
                            d-md-none
                            d-lg-table-cell
                            d-xl-table-cell
                            d-xxl-table-cell
                        "
                        >
                            {dayjs(application.date).format('YYYY-MM-DD HH:mm')}
                        </td>
                        <td
                            className="
                            d-none
                            d-sm-none
                            d-md-none
                            d-lg-table-cell
                            d-xl-table-cell
                            d-xxl-table-cell
                        "
                        >
                            {application.user}
                        </td>
                        <td className="                            d-none                            d-sm-table-cell                            d-md-table-cell                            d-lg-none                            d-xl-none                            d-xxl-none                        ">
                            {application.name}
                            <br />
                            {dayjs(application.date).format('YYYY-MM-DD HH:mm')}
                            <br />
                            {application.user}
                        </td>
                        <td
                            className="
                            d-table-cell
                            d-sm-none
                            d-md-none
                            d-lg-none
                            d-xl-none
                            d-xxl-none                        "
                        >
                            {application.name}
                            <br />
                            {dayjs(application.date).format('YYYY-MM-DD HH:mm')}
                            <br />
                            {application.user}
                            <br />
                            {application.statusName}
                        </td>
                        <td
                            className="
                            d-none
                            d-sm-table-cell
                            d-md-table-cell
                            d-lg-table-cell
                            d-xl-table-cell
                            d-xxl-table-cell
                        "
                        >
                            {application.statusName}
                        </td>
                        <td>
                            {application.showButtons ? (
                                <>
                                    <Accept application={application} />
                                    <Decline application={application} />
                                </>
                            ) : null}
                        </td>
                        <td>
                            <Download
                                url={`/api/fulfillments/download/${application.id}`}
                                filename={`Wniosek_${dayjs(application.date).format('YYYY-MM-DD')}`}
                            />
                        </td>
                    </tr>
                ))}
            </tbody>
        </Table>
    )
}

export default Mine
