import { createApplication } from 'api/applications'
import { useFormik } from 'formik'
import { Question, QuestionTypes } from 'models/applications'
import React from 'react'
import { Button, Form, Stack } from 'react-bootstrap'
import { useMutation, useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

type Props = {
    id: number
    questions: Question[]
}

const QuestionsForm: React.FC<Props> = ({id, questions}) => {
    const initialValues = Object.fromEntries(
        questions.map((q) => {
            const val: string | string[] =
                q.field === QuestionTypes.MultiOption ? [] : ''
            return [q.name, val]
        })
    )

    const navigate = useNavigate()
    const queryClient = useQueryClient()
    const {isLoading, mutate} = useMutation(createApplication, {
        onSuccess: () => {
            queryClient.invalidateQueries('applications')
            toast.success('Wniosek został zapisany.')
            navigate('/')
        },
        onError: (error: any) => {
            error?.response?.data?.detail.forEach((detail: any) => {
                toast.error(detail?.error)
            })
        }
    })
    const formik = useFormik({
        initialValues,
        validate: (values: any) => {
            const errors: any = {}
            questions.forEach((q) => {
                if (
                    q.required &&
                    (values[q.name] === '' || values[q.name].length === 0)
                ) {
                    errors[q.name] = 'Proszę wybrać wartość'
                }
            })

            return errors
        },
        onSubmit: (values: any) => {
            mutate({id, data: values})
        },
    })

    const create = ({field, label, name, required, options}: Question) => {
        switch (field) {
            case QuestionTypes.Text:
            case QuestionTypes.Date:
                return (
                    <>
                        <Form.Label htmlFor={name}>{label}</Form.Label>
                        <Form.Control
                            type={field}
                            id={name}
                            required={required}
                            {...formik.getFieldProps(name)}
                        />
                        {formik.touched[name] && formik.errors[name] && (
                            <div className="text-danger mt-2">
                                {formik.errors[name]}
                            </div>
                        )}
                    </>
                )
            case QuestionTypes.SingleOption:
                return (
                    <>
                        <label className="form-label">{label}</label>
                        <Form.Select onChange={(e) => {
                            formik.setFieldValue(name, e.target.value)
                        }}>
                            <option id={`option-null-${id}`} key={null} value={''}>wybierz</option>
                            {Object.entries(options!).map((o, i) => {
                                const [val, lab] = o
                                return (
                                    <option
                                        id={`option-${val}-${id}`}
                                        key={val}
                                        value={val}
                                    >{lab}</option>
                                )
                            })}
                        </Form.Select>
                        {formik.touched[name] && formik.errors[name] && (
                            <div className="text-danger mt-2">
                                {formik.errors[name]}
                            </div>
                        )}
                    </>
                )
            case QuestionTypes.MultiOption:
                return (
                    <>
                        <label className="form-label">{label}</label>
                        {Object.entries(options!).map((o, i) => {
                            const [val, lab] = o
                            return (
                                <Form.Check
                                    type="checkbox"
                                    label={lab}
                                    key={val}
                                    id={`chk-${val}-${i}`}
                                    checked={formik.values[name].includes(val)}
                                    onChange={(e) => {
                                        if (e.target.checked) {
                                            formik.setFieldValue(name, [
                                                ...(formik.values[
                                                    name
                                                    ] as string[]),
                                                val,
                                            ])
                                        } else {
                                            formik.setFieldValue(
                                                name,
                                                (
                                                    formik.values[
                                                        name
                                                        ] as string[]
                                                ).filter((v) => v !== val)
                                            )
                                        }
                                    }}
                                />
                            )
                        })}
                        {formik.touched[name] && formik.errors[name] && (
                            <div className="text-danger mt-2">
                                {formik.errors[name]}
                            </div>
                        )}
                    </>
                )
            default:
                return null
        }
    }

    return (
        <form onSubmit={formik.handleSubmit}>
            <Stack gap={3}>
                {questions.map((q) => (
                    <div key={q.name}>{create(q)}</div>
                ))}
                <Button
                    type="submit"
                    variant="primary"
                    className="mx-auto"
                    disabled={isLoading}
                >
                    Zapisz
                </Button>
            </Stack>
        </form>
    )
}

export default QuestionsForm
