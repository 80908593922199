import React from 'react'
import { VscFilePdf } from 'react-icons/vsc'
import api from '../api/api'

interface IProps {
    url: string
    filename: string
}

export const Download = ({ filename, url }: IProps) => {
    async function download() {
        const resp = await api.get(url, { responseType: 'blob' })
        const file = resp.data

        generateFile(file)
    }

    function generateFile(data: any) {
        const url = window.URL.createObjectURL(new Blob([data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', getFileExtension(data.type))
        document.body.appendChild(link)
        link.click()
    }

    function getFileExtension(fileType: string) {
        switch (fileType) {
            case 'image/png':
                return `${filename}.png`
            case 'image/jpg':
                return `${filename}.jpg`
            case 'application/pdf':
                return `${filename}.pdf`
            default:
                return `${filename}.jpg`
        }
    }

    return (
        <VscFilePdf
            size="1.5rem"
            role="button"
            onClick={async () => {
                await download()
            }}
        />
    )
}

export default Download
