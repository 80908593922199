import React, { useContext } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { AuthContext } from "./AuthProvider";
import Layout from "components/Layout";
import { Login, Mine } from "screens";
import { New, Questions } from "screens/Applications";
import Dashboard from "../screens/Dashboard/Dashboard";
import Documents from "../screens/Documents";

const Router = () => {
    const { accessToken } = useContext(AuthContext);

    return (
        <BrowserRouter>
            <Routes>
                {accessToken ? (
                    <Route path="" element={<Layout />}>
                        <Route path="/" element={<Dashboard />} />
                        <Route path="/documents" element={<Documents />} />
                        <Route path="/applications/list" element={<Mine />} />
                        <Route path="applications/new/:id" element={<New />} />
                        <Route path="applications/new" element={<New />} />
                        <Route path="applications/new/:id/questions" element={<Questions />} />
                    </Route>
                ) : (
                    <Route path="/" element={<Login />} />
                )}
                {/* <Route path="*" element={<Navigate to="/" />} /> */}
            </Routes>
        </BrowserRouter>
    );
};

export default Router;
