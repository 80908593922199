import {Application, ApplicationStatus} from "../../models/applications";
import {declineApplication} from "../../api/applications";
import {Button} from "react-bootstrap";
import React from "react";

interface IProps {
    application: Application
}

export const Decline = (props: IProps) => {
    const application = props.application
    return (
        <Button
            variant="danger"
            size="sm"
            className="me-2"
            onClick={async () => {
                await declineApplication({id: application.id})
                window.location.reload();
            }}
        >
            {parseInt(application.status) === ApplicationStatus.New ? `Anuluj` : `Odrzuć`}
        </Button>
    )
}

export default Decline