import {
    Monitoring,
    Question,
    CreateApplicationPayload,
    Application,
    AcceptApplicationPayload,
    DeclineApplicationPayload,
    DownloadApplicationPayload,
} from 'models/applications'
import api from './api'

export const fetchMonitorings = async (category?: number) => {
    let url = `/api/monitorings/user`
    if (category) {
        url += `/${category}`
    }
    const resp = await api.get<Monitoring[]>(url)
    return resp.data
}

export const fetchQuestions = async (id: number) => {
    const resp = await api.get<Question[]>(`/api/fulfillments/questions/${id}`)
    return resp.data
}

export const createApplication = async (payload: CreateApplicationPayload) => {
    const resp = await api.post(
        `/api/fulfillments/create/${payload.id}`,
        payload.data
    )
    return resp.data
}

export const acceptApplication = async (payload: AcceptApplicationPayload) => {
    const resp = await api.post(`/api/fulfillments/accept/${payload.id}`)

    return resp.data
}

export const declineApplication = async (payload: DeclineApplicationPayload) => {
    const resp = await api.post(`/api/fulfillments/decline/${payload.id}`)
    return resp.data
}

export const downloadApplication = async (payload: DownloadApplicationPayload) => {
    const resp = await api.post(`/api/fulfillments/download/${payload.id}`, {}, {responseType: 'blob'})
    return resp.data
}

export const fetchApplications = async () => {
    const url = `/api/fulfillments/mine`
    const resp = await api.get<Application[]>(url)
    return resp.data
}
