export enum UserGroup {
    Scheduler = 'scheduler',
}

export enum ShiftType {
    Shift = 1,
    Free,
    Vacation,
    SickLeave,
}

export enum Contract {
    All = 1,
    UoP,
    UZ,
}

export enum SchedulesLayout {
    Stations,
    Users,
}

export enum HourSummary {
    MonthStation = 'month-station',
    UserStation = 'user-station',
    Station = 'station',
    Day = 'day',
    MonthRemaining = 'month-remaining',
    MonthUser = 'month-user',
    User = 'user',
}

export const shiftTypeNames = {
    [ShiftType.Shift]: 'Zmiana',
    [ShiftType.Free]: 'Wolne',
    [ShiftType.Vacation]: 'Urlop',
    [ShiftType.SickLeave]: 'Zwolnienie',
}

export const contractNames = {
    [Contract.All]: 'Wszystkie',
    [Contract.UoP]: 'Umowa o pracę',
    [Contract.UZ]: 'Umowa zlecenie',
}

export interface User {
    id: string
    login: string
    firstName: string
    lastName: string
    number: string
    group: UserGroup
    contract: Contract
    phoneNumber?: string
    email?: string
    position?: string
    supervisor?: string
    department?: string
    numberOfHolidayDays?: number
}

export interface ShiftPayload {
    id?: number
    name: string
    start: string
    end: string
    code: string
    color: string
    count_work_time: boolean
    type: ShiftType
    contract: Contract
}

export interface Shift extends ShiftPayload {
    hours: number
    stations?: Station[]
}

export interface Location {
    id?: number
    name: string
    customer_name: string
    address: string
    post_code: string
    city: string
    stations?: Station[]
}

export interface Station {
    id?: number
    name: string
    description: string
    shifts?: Shift[]
    location_id?: number
}

export interface StationPayload {
    station: Station
    locationId?: number
}

export interface ShiftAssignment {
    shiftId: number
    stationId: number
    checked: boolean
}

export interface UserAssignment {
    locationId: number
    userId: string
    checked: boolean
}

export interface Day {
    number: number
    weekday: string
}

export interface Work {
    id?: number
    date: string
    station_id: number
    shift_id: number
    user_id: string
    shift?: Shift
}

export interface WorkFetchParams {
    locationId: number
    year: number
    month: number
    userId?: string
}

export interface HoursFetchParams extends WorkFetchParams {
    type: HourSummary
}

export interface ValidationResult {
    user: string
    dates: string
    message: string
    highlight?: boolean
}

export interface WorkCache {
    [key: string]: Shift[]
}

export type Errors = {
    [key: string]: string[]
}

export interface LoginPayload extends Login {
    grant_type: 'password'
    client_id: string
    client_secret: ''
}

export interface Login {
    username: string
    password: string
}
