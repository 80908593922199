export enum DocumentType {
  PIT = 1,
  PAYROLL = 2,
}

export const DocumentTypeName = {
  [DocumentType.PIT]: 'PIT',
  [DocumentType.PAYROLL]: 'Pasek płacowy',
}

export interface IDocument {
  id: number
  type: DocumentType
  title: string
  creationDate: Date
}
