import React from 'react'
import { useQuery } from 'react-query'
import { fetchMonitorings } from 'api/applications'
import Spinner from 'components/Spinner'
import { Container } from 'react-bootstrap'
import { Link, useParams } from 'react-router-dom'

export const New = () => {
    const params = useParams()
    const id = params.id ? parseInt(params.id) : undefined

    const { isLoading, data } = useQuery(['monitorings', id], () =>
        fetchMonitorings(id)
    )

    if (isLoading) return <Spinner />

    return (
        <Container>
            <div className="d-grid gap-2">
                {!data?.length && <div>Brak wniosków</div>}

                {data?.map((a) => (
                    <Link
                        to={
                            id
                                ? `/applications/new/${a.id}/questions`
                                : `/applications/new/${a.id}`
                        }
                        className="btn btn-primary btn-lg"
                        key={a.id}
                    >
                        {a.name}
                    </Link>
                ))}
            </div>
        </Container>
    )
}

export default New
