export interface Monitoring {
    id: number
    name: string
    fulfilled: boolean
}

export enum QuestionTypes {
    Date = 'date',
    Text = 'text',
    SingleOption = 'singleOption',
    MultiOption = 'multipleOptions',
}

export interface Question {
    field: QuestionTypes
    name: string
    label: string
    required: boolean
    options?: { [k: number]: string }
}

export interface CreateApplicationPayload {
    data: { [k: string]: string }
    id: number
}

export interface AcceptApplicationPayload {
    id: number
}

export interface DeclineApplicationPayload {
    id: number
}

export interface DownloadApplicationPayload {
    id: number
}

export interface Application {
    id: number
    name: string
    date: Date
    user?: string
    assignedUser?: string
    status: string
    statusName: string
    showButtons: boolean
}

export enum ApplicationStatus {
    New = 1,
    InProgress = 2,
    Done = 3,
    Canceled = 4
}