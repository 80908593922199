import {Application, ApplicationStatus} from "../../models/applications";
import {acceptApplication} from "../../api/applications";
import {Button} from "react-bootstrap";
import React from "react";

interface IProps {
    application: Application
}

export const Accept = (props: IProps) => {
    const application = props.application
    return (
        <Button
            variant="success"
            size="sm"
            className="me-2"
            onClick={async () => {
                await acceptApplication({id: application.id})
                window.location.reload();
            }}
        >
            {parseInt(application.status) === ApplicationStatus.New ? `Złóż` : `Zaakceptuj`}
        </Button>
    )
}

export default Accept