import {User} from 'models'
import api from './api'

export const fetchUsers = async (): Promise<User[]> => {
    const resp = await api.get(`/users`)
    return resp.data
}

export const fetchProfile = async (): Promise<User> => {
    const resp = await api.get(`/api/users/profile`)
    return resp.data
}

export const fetchHoliday = async(): Promise<User> => {
    const resp = await api.get(`/api/users/holiday`)
    return resp.data
}