import React, { useContext, useState } from "react";
import { Col, Nav, Navbar, Row } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../providers/AuthProvider";
import { RiLogoutCircleRLine } from "react-icons/ri";
import { toast } from "react-toastify";

const Main = () => {
    const { logout } = useContext(AuthContext);
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const [expanded, setExpanded] = useState(false);

    const onLogout = () => {
        logout();
        toast.success("Pomyślnie wylogowano");
        navigate("/");
    };

    const hideNavbar = () => {
        setExpanded(false);
    };
    return (
        <>
            <Navbar
                expand={false}
                id="navigation"
                className="mb-3"
                collapseOnSelect={true}
                onToggle={() => {
                    setExpanded(!expanded);
                }}
                expanded={expanded}
                style={{ boxShadow: "0 0.15rem 0.5rem rgba(0, 0, 0, 0.15)" }}
            >
                <Container fluid>
                    <Navbar.Brand
                        as={Link}
                        to="/"
                        className="fs-6 fw-light d-flex align-items-center gap-2"
                        onClick={hideNavbar}
                    >
                        <img src={`/${process.env.REACT_APP_SYGNET}`} alt={process.env.REACT_APP_TITLE} height="50" />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="main-nav" />
                    <Navbar.Collapse id="main-nav">
                        <Nav className="me-auto">
                            <Nav.Link
                                as={Link}
                                to="/applications/list"
                                active={pathname === "/applications/list"}
                                onClick={hideNavbar}
                            >
                                Lista wniosków
                            </Nav.Link>
                            <Nav.Link
                                as={Link}
                                to="/applications/new"
                                active={pathname === "/applications/new"}
                                onClick={hideNavbar}
                            >
                                Złóż wniosek
                            </Nav.Link>
                            <Nav.Link as={Link} to="/documents" active={pathname === "/documents"} onClick={hideNavbar}>
                                Dokumenty
                            </Nav.Link>
                            <Nav.Link onClick={onLogout}>
                                <RiLogoutCircleRLine size="1.5rem" />
                                &thinsp;Wyloguj
                            </Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <Container fluid>
                <Row>
                    <Col>
                        <Outlet />
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default Main;
