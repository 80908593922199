import React, { useContext, useState } from 'react'
import { Button, FloatingLabel, Form } from 'react-bootstrap'
import { AuthContext } from 'providers/AuthProvider'
import FieldError from 'components/FieldError'
import { Login as LoginType } from 'models'

export const Login = () => {
    const [state, setState] = useState<LoginType>({
        username: '',
        password: '',
    })
    const { login, errors, clearErrors } = useContext(AuthContext)

    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        clearErrors()
        login(state)
    }

    return (
        <div className="login-container">
            <div className="d-flex justify-content-center">
                <img src={`/${process.env.REACT_APP_LOGO}`} alt={process.env.REACT_APP_TITLE} />
            </div>
            <div>
                <Form onSubmit={onSubmit}>
                    <FloatingLabel
                        controlId="floatingInput"
                        label="Login"
                        className="mb-3"
                    >
                        <Form.Control
                            type="text"
                            placeholder="Login"
                            onChange={(e) =>
                                setState({
                                    ...state,
                                    username: e.target.value,
                                })
                            }
                            value={state.username}
                            isInvalid={!!errors?.login}
                        />
                        <FieldError errors={errors} name="login" />
                    </FloatingLabel>
                    <FloatingLabel
                        controlId="floatingPassword"
                        label="Hasło"
                        className="mb-3"
                    >
                        <Form.Control
                            type="password"
                            placeholder="Hasło"
                            onChange={(e) =>
                                setState({ ...state, password: e.target.value })
                            }
                            value={state.password}
                            isInvalid={!!errors?.password}
                        />
                        <FieldError errors={errors} name="password" />
                    </FloatingLabel>
                    <Button
                        type="submit"
                        variant="primary"
                        size="lg"
                        className="w-100"
                    >
                        Zaloguj
                    </Button>
                </Form>
            </div>
        </div>
    )
}

export default Login
