import React from 'react'
import { FaAt, FaPhone } from "react-icons/fa";
import { useQuery } from "react-query";
import { fetchProfile } from "../../api/users";
import Spinner from "../../components/Spinner";

export const ProfileTile = () => {
    const {data, isLoading} = useQuery('profile', fetchProfile)

    if (isLoading) return <Spinner/>

    return (
        <div className="col-12 col-xl-12">
            <div className="py-4 px-5 px-sm-2 px-md-5 px-xxl-5 border row row-cols-1 g-0">
                <div className="mb-4 mb-md-0 col">
                    <h5>{data?.firstName}&nbsp;{data?.lastName}&thinsp;&ndash;&thinsp;profil</h5>
                    <div className="row row-cols-3 g-0">
                        <div className="col-6 text-left">
                            <span>Stanowisko:&thinsp;{data?.position}</span>
                        </div>
                        <div className="col-5 d-flex justify-content-start align-items-center">
                            <span>{data?.phoneNumber}</span>
                        </div>
                        <div className="col-1 d-flex justify-content-center align-items-center">
                            <FaPhone role="button"></FaPhone>
                        </div>
                    </div>
                    <div className="row row-cols-3 g-0">
                        <div className="col-6 text-left">
                            <span>Departament:&thinsp;{data?.department}</span>
                        </div>
                        <div className="col-5 d-flex justify-content-start align-items-center">
                            <span>{data?.email}</span>
                        </div>
                        <div className="col-1 d-flex justify-content-center align-items-center">
                            <FaAt role="button"></FaAt>
                        </div>
                    </div>
                    <div className="row row-cols-3 g-0">
                        <div className="col-6 text-left">
                            <span>Przełożony:&thinsp;{data?.supervisor}</span>
                        </div>
                        <div className="col-5 d-flex justify-content-start align-items-center">
                            <span>&nbsp;</span>
                        </div>
                        <div className="col-1 d-flex justify-content-center align-items-center">
                            <span>&nbsp;</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProfileTile